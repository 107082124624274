// toggle navigation close
const navClose = document.querySelector('.nav__close');
const nav = document.querySelector('.nav');

if (navClose && nav) {
  navClose.addEventListener('click', function() {
    nav.classList.remove('nav--active');
  });
}

// set up nav toggles
const subs = nav.querySelectorAll('.sub-menu');
const placeholders = nav.querySelectorAll('[href="#"]');

for (const sub of subs) {
  const parent = sub.parentNode;
  const button = document.createElement('button');

  const expanded = () => button.getAttribute('aria-expanded') === 'true';

  button.classList.add('nav__toggle');
  button.setAttribute('aria-expanded', 'false');
  button.innerHTML = `
    <span class="svgstore svgstore--angle-down">
      <svg>
        <title></title>
        <use xlink:href="/wp-content/themes/urbnlivn/dist/img/svgstore.svg#angle-down"></use>
      </svg>
    </span>
  `;
  parent.insertBefore(button, sub);

  button.addEventListener('click', () => {
    button.setAttribute('aria-expanded', !expanded());
  });

  document.addEventListener('click', (e) => {
    if (expanded() && !parent.contains(e.target)) {
      button.setAttribute('aria-expanded', 'false');
    }
  });
}

for (const placeholder of placeholders) {
  placeholder.addEventListener('click', (e) => {
    e.preventDefault();
    e.target.nextElementSibling.click();
  });
}
